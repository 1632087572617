import { graphql, Link, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../components/Banner';
import Layout from '../components/Layout';

const Index = (): JSX.Element => {
    const punkImageData = useStaticQuery(
        graphql`
            query {
                punkImage8348: file(relativePath: { eq: "assets/images/stitchedpunk-closeup-8348.jpg" }) {
                    childImageSharp {
                        fluid(quality: 75, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                punkImage6613: file(relativePath: { eq: "assets/images/stitchedpunk-closeup-6613.jpg" }) {
                    childImageSharp {
                        fluid(quality: 75, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                punkImage9670: file(relativePath: { eq: "assets/images/stitchedpunk-closeup-9670.jpg" }) {
                    childImageSharp {
                        fluid(quality: 75, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                punkImage5708: file(relativePath: { eq: "assets/images/stitchedpunk-closeup-5708.jpg" }) {
                    childImageSharp {
                        fluid(quality: 75, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `,
    );

    return (
        <Layout>
            <Helmet
                title="StitchedPunks"
                meta={[
                    {
                        name: 'description',
                        content:
                            'Beautiful and hand-crafted embroidery based on your beloved CryptoPunk NFTs. Exclusively for punk owners.',
                    },
                    { name: 'keywords', content: 'cryptopunks, stitchedpunks, nft, ethereum, blockchain, embroidery' },
                ]}
            />

            <Banner />

            <div id="main">
                <section className="bg-one">
                    <div className="inner">
                        <header className="major">
                            <h2>Idea</h2>
                        </header>
                        <p>
                            StitchedPunks are embroidered artworks based on the famous{' '}
                            <a href="https://www.larvalabs.com/cryptopunks">CryptoPunks</a>. The special feature is, you
                            can only order one, if you own the corresponding CryptoPunk. This means, a StitchedPunk is
                            an exclusive and unique CryptoPunk companion. It is the physical counterpart to your digital
                            artwork and serves as a nice discussion starter in your home or office.
                        </p>
                        <p>
                            A perfect understatement for displaying your CryptoPunk collection. Take a look at the{' '}
                            <Link to="/gallery">gallery</Link> for some inspiration.
                        </p>
                        <ul className="actions">
                            <li>
                                <Link to="/about" className="button next">
                                    More about the project
                                </Link>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="tiles">
                    <article
                        style={{ backgroundImage: `url(${punkImageData.punkImage8348.childImageSharp.fluid.src})` }}
                        className="punk-tile-8348">
                        <header className="major">
                            <h3>StitchedPunk #8348</h3>
                            <p>The king of all attributes</p>
                        </header>
                        <Link to="/details/8348" className="link primary"></Link>
                    </article>
                    <article
                        style={{ backgroundImage: `url(${punkImageData.punkImage6613.childImageSharp.fluid.src})` }}
                        className="punk-tile-6613">
                        <header className="major">
                            <h3>StitchedPunk #6613</h3>
                            <p>Watching the future in VR</p>
                        </header>
                        <Link to="/details/6613" className="link primary"></Link>
                    </article>
                </section>
                <section>
                    <div className="inner">
                        <header className="major">
                            <h2>Details</h2>
                        </header>
                        <p>
                            Simply scan the QR code on the back to find out more about the specific details of your
                            CryptoPunk, show its attributes or have a quick look at its trading history. One punk has
                            been fought over in a bidding competition, another punk was never sold and is a rare
                            developer asset.
                        </p>
                        <p>Every punk has a story to tell.</p>
                        <ul className="actions">
                            <li>
                                <Link to="/details" className="button next">
                                    Finished StitchedPunks
                                </Link>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="tiles">
                    <article
                        style={{ backgroundImage: `url(${punkImageData.punkImage9670.childImageSharp.fluid.src})` }}
                        className="punk-tile-9670">
                        <header className="major">
                            <h3>StitchedPunk #9670</h3>
                            <p>Fiery red...</p>
                        </header>
                        <Link to="/details/9670" className="link primary"></Link>
                    </article>
                    <article
                        style={{ backgroundImage: `url(${punkImageData.punkImage5708.childImageSharp.fluid.src})` }}
                        className="punk-tile-5708">
                        <header className="major">
                            <h3>StitchedPunk #5708</h3>
                            <p>...or eye-catching green?</p>
                        </header>
                        <Link to="/details/5708" className="link primary"></Link>
                    </article>
                </section>
                <section>
                    <div className="inner">
                        <header className="major">
                            <h2>Companion NFT</h2>
                        </header>
                        <div className="box alt">
                            <div className="grid-wrapper">
                                <div className="col-9">
                                    <p>
                                        Every StitchedPunk comes with a unique NFT. It serves as a companion to your
                                        CryptoPunk NFT, confirms that you received your order and allows you to
                                        customize the detail page of your StitchedPunk in the future.
                                    </p>
                                    <ul className="actions">
                                        <li>
                                            <Link to="/profile" className="button next">
                                                Get your StitchedPunk
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-3">
                                    <span className="image fit">
                                        <Link to="/details/9670">
                                            <StaticImage
                                                src="../assets/images/stitchedpunk-nft-9670.jpg"
                                                alt="NFT artwork for StitchedPunk #9670"
                                                className="inverted-hover"
                                            />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default Index;
