import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

const Banner = (): JSX.Element => {
    const headerImageData = useStaticQuery(
        graphql`
            query {
                headerImage: file(relativePath: { eq: "assets/images/stitchedpunk-example-triple.jpg" }) {
                    childImageSharp {
                        fluid(quality: 75, maxWidth: 3840) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `,
    );

    return (
        <section
            id="banner"
            className="major"
            style={{ backgroundImage: `url(${headerImageData.headerImage.childImageSharp.fluid.src})` }}>
            <div className="inner">
                <header className="major">
                    <h1>StitchedPunks</h1>
                </header>
                <div className="content">
                    <p>
                        Beautiful and hand-crafted embroidery
                        <br />
                        based on your beloved CryptoPunk NFTs.
                        <br />
                        Exclusively for punk owners.
                    </p>
                    <ul className="actions">
                        <li>
                            <Link to="/profile" className="button next">
                                Get Your StitchedPunk
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Banner;
